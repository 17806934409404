import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {fetchUserStatus} from '../actions';
import axios from 'axios';
import {
  Card,
  Image,
  Button,
  Icon,
  Container,
  Grid
} from 'semantic-ui-react';

const Product = (props) => (
  <Card>
    <Card.Content>
      <Image size='tiny' floated='left' src={props.cover} />
      <Card.Header>{props.name}</Card.Header>
      <Card.Meta>
        <span className='date'><b color="green" >{props.price} RMB</b></span>
      </Card.Meta>
      <Card.Description>
          {props.descriptions.map((description)=>{
            return <p>{description}</p>
          })}
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
        <div className='ui buttons'>
          <Link as="a" to={"/shop/" + props.id}>
            <Button basic color='green'>
              Buy It Now
            </Button>
          </Link>
        </div>
    </Card.Content>
  </Card>
)

class Main extends Component {
    state = {
      products:[]
    }

    constructor() {
        super(...arguments);
        this.fetchProducts();
    }

    async fetchProducts(){
      const {data} = await axios.post("/products");
      this.setState({
        products:data.products.filter((product)=>{
          return [3001,1004,1005,1006,1003].indexOf(product.id*1) != -1
        })
      })
    }

    render() {

        return (
          <Container>
          <Grid stackable>

            <Grid.Row  columns={4}>
              {this.state.products.map((product)=>{
                return (<Grid.Column style={{paddingTop:"20px"}}>
                  <Product
                    id={product.id}
                    cover={product.cover}
                    price={product.price}
                    descriptions={product.descriptions}
                    name={product.name}
                    left={product.left} />
                </Grid.Column>)
              })}
            </Grid.Row>

          </Grid>

          </Container>
        )
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
