import axios from 'axios';
import {REALTIME_UPDATE,FETCH_USER_INFO_STARTED, FETCH_USER_INFO_SUCCESS, FETCH_USER_INFO_FAILURE} from './actionTypes';

export const fetchUserInfoStarted = () => ({
    type: FETCH_USER_INFO_STARTED
});

export const fetchUserInfoSuccess = (info) => ({
    type: FETCH_USER_INFO_SUCCESS,
    payload: {
        info
    }
});

export const fetchUserInfoFailure = (error) => ({
    type: FETCH_USER_INFO_FAILURE,
    payload: {
        error
    }
});

export const realTimeUpdate = (data) => ({
    type: REALTIME_UPDATE,
    payload: data
});

export const fetchUserInfo = () => {
    return (dispatch) => {
        dispatch(fetchUserInfoStarted());
        return axios.get('/user/info').then(({data}) => {
            const {code, data: info} = data;
            if (code !== 0) {
                dispatch(fetchUserInfoFailure(data.message || '获取用户基础信息失败'));
            } else {
                dispatch(fetchUserInfoSuccess(info.userInfo));
            }
        }).catch((err) => {
            console.error(err);
            dispatch(fetchUserInfoFailure(err));
        });
    };
};
