import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Loading from '../Loading';
import Loadable from 'react-loadable';

const LoadableAdd = Loadable({
    loader: () => import('./Add'),
    loading: Loading,
});

const LoadableSend = Loadable({
    loader: () => import('./Send'),
    loading: Loading,
});

const LoadableMain = Loadable({
    loader: () => import('./Main'),
    loading: Loading,
});

const LoadableAssets = Loadable({
    loader: () => import('./Assets'),
    loading: Loading,
});

const LoadableOrders = Loadable({
    loader: () => import('./Orders'),
    loading: Loading,
});

const LoadableProxy = Loadable({
    loader: () => import('./Proxy'),
    loading: Loading,
});

const AllRouters = () => (
    <Switch>
      <Route exact path='/admin' component={LoadableMain} />
      <Route exact path='/admin/add' component={LoadableAdd} />
      <Route exact path='/admin/send' component={LoadableSend} />
      <Route exact path='/admin/proxy' component={LoadableProxy} />
      <Route exact path='/admin/assets' component={LoadableAssets} />
      <Route exact path='/admin/orders' component={LoadableOrders} />
    </Switch>
);

export default AllRouters;
