import axios from 'axios';
import {FETCH_USER_STATUS_STARTED, FETCH_USER_STATUS_SUCCESS, FETCH_USER_STATUS_FAILURE} from './actionTypes';
import {FETCH_SUPPORT_INFO_STARTED, FETCH_SUPPORT_INFO_SUCCESS, FETCH_SUPPORT_INFO_FAILURE} from './actionTypes';

export const fetchUserStatusStarted = () => ({
    type: FETCH_USER_STATUS_STARTED
});

export const fetchUserStatusSuccess = (status) => ({
    type: FETCH_USER_STATUS_SUCCESS,
    payload: {
        status
    }
});

export const fetchUserStatusFailure = (error) => ({
    type: FETCH_USER_STATUS_FAILURE,
    payload: {
        error
    }
});

export const fetchUserStatus = () => {
    return (dispatch) => {
        dispatch(fetchUserStatusStarted());
        return axios.get('/user/status').then(({data}) => {
            const {code, from, data: status} = data;
            if (code !== 0) {
                if (code === -100) {
                    dispatch(fetchUserStatusSuccess({unpaid: true, gift:from||"" }));
                } else if (code === -101){
                    dispatch(fetchUserStatusSuccess({expired: true}));
                } else if (code === -2){
                    window.location = "/";
                } else {
                    dispatch(fetchUserStatusFailure(data.message || '获取用户状态信息失败'));
                }
            } else {
                dispatch(fetchUserStatusSuccess(status));
            }
        }).catch((err) => {
            console.error(err);
            dispatch(fetchUserStatusFailure(err));
        });
    };
};

export const fetchSupportInfoStarted = () => ({
    type: FETCH_SUPPORT_INFO_STARTED,
});

export const fetchSupportInfoSuccess = (info) => ({
    type: FETCH_SUPPORT_INFO_SUCCESS,
    payload: {
        info,
    },
});

export const fetchSupportInfoFailure = (error) => ({
    type: FETCH_SUPPORT_INFO_FAILURE,
    payload: {
        error,
    },
});

export const fetchSupportInfo = () => {
    return (dispatch) => {
        dispatch(fetchUserStatusStarted());
        return axios.get('/user/support').then(({data}) => {
            const {code, data: info} = data;
            if (code !== 0) {
                dispatch(fetchUserStatusFailure());
            } else {
                dispatch(fetchSupportInfoSuccess(info));
            }
        }).catch((err) => {
            console.error(err);
            dispatch(fetchUserStatusFailure(err));
        })
    }
};
