import React, { Component } from 'react'
import {Container, Icon, Menu, Responsive, Sidebar} from 'semantic-ui-react'

class DesktopContainer extends Component {
    render() {
        const { menuColor, menuItems, rightMenuItems, children } = this.props

        return (
            <Responsive minWidth={1024} style={{background:"#fffcf7"}}>
                <div style={{ background:"f7f7f7", display:"flex", minHeight:"100vh", flexDirection:"column" }}>
                    <Menu  style={{ background:"#fff", borderRadius:0}} borderless stackable>
                        <Container>
                            {menuItems}
                            {rightMenuItems}
                        </Container>
                    </Menu>
                    {children}
                </div>
            </Responsive>
        )
    }
}

class MobileContainer extends Component {
    state = {}

    handlePusherClick = () => {
        const { sidebarOpened } = this.state

        if (sidebarOpened) this.setState({ sidebarOpened: false })
    }

    handleToggle = () => this.setState({ sidebarOpened: !this.state.sidebarOpened })

    render() {
        const { menuColor, menuItems, rightMenuItems, children } = this.props
        const { sidebarOpened } = this.state

        return (
            <Responsive maxWidth={1023}>
                <Sidebar.Pushable>
                    <Sidebar as={Menu} animation='push' width='thin' vertical visible={sidebarOpened}>
                        {menuItems}
                    </Sidebar>

                    <Sidebar.Pusher dimmed={sidebarOpened} onClick={this.handlePusherClick} style={{ minHeight: '100vh' }}>
                        <Menu color={menuColor} style={{ background:"#0199d6", borderRadius:0}} inverted secondary>

                            <Menu.Item onClick={this.handleToggle}>
                                <Icon name='sidebar' />
                            </Menu.Item>
                            {rightMenuItems}
                        </Menu>
                        {children}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Responsive>
        )
    }
}

const ResponsiveContainer = (props) => (
    <div>
        <DesktopContainer {...props} />
        <MobileContainer {...props} />
    </div>
);

export default ResponsiveContainer;
