import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  de: {
    translation: {
      "glados": "GLaDOS",
      "landing.title": "通用网络游戏加速器",
      "landing.subtitle": "General Accelerator for the rest of us",
      "landing.intro": "Reach multiple endpoints from GLaDOS.",
      "landing.apply": "Talk to Sales",
      "landing.enter": "Enter",
      "landing.notes": "Free 14-day trial / Easy setup",
      "landing.edge.title": "Enterprise DNS Manager",
      "landing.edge.subtitle":"DNS based networking manager",
      "landing.edge.p1":"GLaDOS blocks against known malicious domains, preventing your computers and IoT devices from connecting to malware or phishing sites. Whenever a GLaDOS user clicks on a website link or types in an address into a web browser, GLaDOS checks the site against a list of domains combined from 19 different threat intelligence partners.",
      "landing.edge.p2":"Each threat intelligence partner supplies a list of malicious domains based on their heuristics which examine such factors as scanned malware discovery, network IDS past behaviors, visual object recognition, optical character recognition (OCR), structure and linkages to other sites, and individual reports of suspicious or malicious behavior. ",
      "landing.edge.p3":"Based on the results, GLaDOS resolves or denies the lookup attempt, preventing connections to malicious sites when there is a match.",
      "landing.footer1":"Unstopable Connection Experience",
      "landing.footer2":"via Edge Networks"
    }
  },
  en: {
    translation: {
      "glados": "GLaDOS",
      "landing.title": "Your trustabe networking manager",
      "landing.subtitle": "Global data audit and transfer leader",
      "landing.intro": "GLaDOS is your networking manager, provides you reliable DNS and data connections with Global IDC , Business Networks and Cloud services",
      "landing.apply": "Apply Today",
      "landing.enter": "Enter",
      "landing.notes":"Free 14-day trial / Easy setup",
      "landing.edge.title":"GLaDOS : Your private edge networks",
      "landing.edge.subtitle":"DNS based networking manager",
      "landing.edge.p1":"The journey starts when a user starts a requests. GLaDOS responds to the user's request from an Edge Network location that will provide the lowest latency. ",
      "landing.edge.p2":"Our Edge Network receives the user's request and passes it to the nearest data center.",
      "landing.edge.p3":"The app or browser retrieves the content required; this can come from multiple GLaDOS servers, including our data centers, and Edge Nodes.",
      "landing.footer1":"",
      "landing.footer2":""
    }
  },
  cn: {
    translation: {
      "glados": "GLaDOS",
      "landing.title": "您可靠的网络管理器",
      "landing.subtitle":"全球数据审计和传输专家",
      "landing.intro": "GLaDOS 是您的网络管理器，通过边缘网络加速技术为你提供可靠的 DNS, TCP, HTTP 和 HTTPS 连接全球 IDC、教育资源、公司网络和云服务。",
      "landing.apply": "现在申请",
      "footer1":"通过 GLaDOS 访问内容提供商、公司网络和公共云服务。",
      "footer2":"更快、更安全的互联网只有一步之遥",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "cn",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
