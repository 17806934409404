const readableMap = {
    'charge:auto': '每日自动扣除',
    'fund': '增加天数',
};

// YYYY-MM-DD
const ts2date = (ts) => {
    const d = new Date(ts);
    if (isNaN(d.getTime())) {
        return '';
    }
    const year = d.getFullYear().toString().padStart(4, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

// YYYY-MM-DD HH:mm:ss
const ts2datetime = (ts) => {
    const date = ts2date(ts);
    const d = new Date(ts);
    if (!date || isNaN(d.getTime())) {
        return '';
    }
    const hours = d.getHours().toString().padStart(2, '0');
    const minutes = d.getMinutes().toString().padStart(2, '0');
    const seconds = d.getSeconds().toString().padStart(2, '0');
    return `${date} ${hours}:${minutes}`;
    //return `${date} ${hours}:${minutes}:${seconds}`;
};

const ts2readable = (ts) => {
    const date = ts2date(ts);
    const d = new Date(ts);
    if (!date || isNaN(d.getTime())) {
        return '';
    }
    const hours = d.getHours().toString().padStart(2, '0');
    const minutes = d.getMinutes().toString().padStart(2, '0');
    const seconds = d.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

const renderBalances = (data, index) => {
    return {
        key: data.id,
        cells: [
            {content: parseInt(data.balance, 10), key: 'leftDays'},
            ts2date(data.time),
            readableMap[data.business] || data.business,
            {content: parseInt(data.change, 10), key: 'renew'}
        ]
    };
};

const renderDetailBalances = (data, index) => {
    return {
        key: data.id,
        cells: [
            {content: data.id, key: 'id'},
            {content: data.user_id, key: 'user_id'},
            {content: data.asset, key: 'asset'},
            {content: parseInt(data.balance, 10), key: 'leftDays'},
            ts2datetime(data.time),
            readableMap[data.business] || data.business,
            {content: parseInt(data.change, 10), key: 'renew'}
        ]
    };
};

const codeStatusToCh = {
    valid: '有效',
    used: '已使用',
    expired: '已过期',
    obsolete: '已作废'
};

const renderCodeList = (data, index) => ({
    key: data.id,
    cells: [
        {content: data.id, key: 'id'},
        {content: data.code_type, key: 'type'},
        {content: data.code, key: 'code'},
        {content: ts2datetime(data.created_at), key: 'time', width: 4},
        {content: codeStatusToCh[data.status] || data.status, key: 'status'},
        {content: data.days, key: 'days'},
        {content: data.maker, key: 'maker'},
        {content: data.taker, key: 'taker'},
    ],
});

const phoneRegex = /^\+?[0-9-]{6,16}$/;
const codeRegex = /^\d+$/;

const siteName = "GLaDOS";//process.env.REACT_APP_WEBSITE_NAME;
const siteDomain = "glados.us";//process.env.REACT_APP_DOMAIN;

const pageTitle = (title) => {
    return siteName ? `${title} - ${siteName}` : title;
};

export {renderDetailBalances,
    renderBalances,
    readableMap,
    ts2date,
    ts2datetime,
    ts2readable,
    phoneRegex,
    codeRegex,
    siteDomain,
    siteName,
    pageTitle,
    codeStatusToCh,
    renderCodeList,
};
