import {REALTIME_UPDATE, FETCH_USER_INFO_STARTED, FETCH_USER_INFO_SUCCESS, FETCH_USER_INFO_FAILURE} from './actionTypes';

const reducer = (state={loading: false, info: {}, realtime: {}, history: {} }, action) => {
    switch (action.type) {
        case FETCH_USER_INFO_STARTED:
            return {
                ...state,
                loading: true
            };
        case FETCH_USER_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                info: action.payload.info,
                error: false
            };
        case FETCH_USER_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state;
    }
};

export {reducer};
