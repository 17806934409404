import {FETCH_USER_STATUS_STARTED, FETCH_USER_STATUS_SUCCESS, FETCH_USER_STATUS_FAILURE} from './actionTypes';
import {FETCH_SUPPORT_INFO_SUCCESS} from './actionTypes';
import {combineReducers} from 'redux';

const mainReducer = (state = {loading: false, status: {}}, action) => {
    switch (action.type) {
        case FETCH_USER_STATUS_STARTED:
            return {
                ...state,
                loading: true
            };
        case FETCH_USER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action.payload.status
            };
        case FETCH_USER_STATUS_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

const supportState = {
    type: 'wechat',
    value: 'https://u.wechat.com/IOaordoy-HaTsAbkfqpzrhc',
};

const supportReducer = (state = supportState, action) => {
    let info;
    switch (action.type) {
        case FETCH_SUPPORT_INFO_SUCCESS:
            info = action.payload.info;
            return {
                type: info.type || supportState.type,
                value: info.value || supportState.value,
            };
        default:
            return state;
    }
};

// const balanceReducer = (state = {}, action) => {
//     return state;
// };

export default combineReducers({
    main: mainReducer,
    support: supportReducer,
    // balance: balanceReducer
});
