import React, {Fragment,Component} from 'react';
import {connect} from 'react-redux';
import {Link,Redirect} from 'react-router-dom';
import {fetchUserStatus} from '../actions';
import {
  Container,
  List,
  Card,
  Breadcrumb,
  Dimmer,
  Header,
  Progress,
  Form,
  Button,
  Loader,
  Image,
  Segment,
  Grid
} from 'semantic-ui-react';
import axios from "axios";

function More(){
  return(
    <>
  <p>
  Pro Plans: 
  <List  horizontal>
    <List.Item>
      <List.Content>
        <a href='/shop/2012'>100 Days</a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Content>
        <a href='/shop/2013'>200 Days</a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Content>
        <a href='/shop/2014'>400 Days</a>
      </List.Content>
    </List.Item>
  </List>
  </p>
    <p>
    Basic Plans:
  <List  horizontal>
    <List.Item>
      <List.Content>
        <a href='/shop/2001'> 30 Days</a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Content>
        <a href='/shop/2002'> 100 Days</a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Content>
        <a href='/shop/2003'> 200 Days</a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Content>
        <a href='/shop/2004'> 400 Days</a>
      </List.Content>
    </List.Item>
  </List>
  </p>
    </>
  )
}

class Shop extends Component {

    constructor(props) {
        super(...arguments);
        const id = props.match.params.id;
        this.state = {
          id:id,
          percent:0,
          start:false,
          email:"",
          redirect:false,
          loading:true
        }
        this.fetchProduct();
    }

    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    async fetchProduct(){
      const id   = this.state.id;
      const {data}  = await axios.post("/product/" + id);
      data.loading  = false;
      this.setState(data);
    }

    async createOrder(pm_id){

      if(!this.validateEmail(this.state.email)){
        return;
      }

      //var i = 0;
      //setInterval(()=>{
        //i = Math.min(100,i+2);
        //this.setState({percent:i})
      //},600);

      this.setState({start:true})
      const {data}  = await axios.post("/order",{
        base:0,
        host:window.location.host,
        pm_id:pm_id,
        product_id:this.state.id,
        email:this.state.email
      });

      console.log(data)

      if(data.redirect_url){
        window.location.href = data.redirect_url;
      }

    }

    onChange(e) {
      this.setState({
        email:e.target.value
      })
    }

    renderContent(){

        if(this.state.product.track_id > 0){
          return <Fragment>
            <Grid.Row>
            <Grid.Column>
              <Header as='h3' style={{ fontSize: 'em' }}>
                Developer Review:
              </Header>
                  {this.state.product.meta.description.split("\n").map((line)=>{
                    return <p>{line}</p>
                  })}
            </Grid.Column>
            </Grid.Row>

              <Grid.Row  columns={4}>
                  {this.state.product.meta.screenshotUrls.slice(0,4).map((url)=>{
                    return (
                    <Grid.Column>
                      <Card>
                        <Image  bordered rounded size='large' src={url}/>
                      </Card>
                    </Grid.Column>
                    )
                  })}
              </Grid.Row>

              <Grid.Row  columns={4}>
                  {this.state.product.meta.ipadScreenshotUrls.slice(0,4).map((url)=>{
                    return (
                    <Grid.Column>
                      <Card>
                        <Image bordered rounded size='large' src={url}/>
                      </Card>
                    </Grid.Column>
                    )
                  })}
              </Grid.Row>
          </Fragment>
        }else{
          return <Fragment>
              <Grid.Row>
              </Grid.Row>
            </Fragment>
        }

    }

    render() {

//Specify SameSite=None and Secure if the cookie should be sent in cross-site requests. This enables third-party use.
//Specify SameSite=Strict or SameSite=Lax if the cookie should not be sent in cross-site requests.

        return (
        <Container>

          <Dimmer.Dimmable as={Segment} dimmed={this.state.loading}>
          <Dimmer active={this.state.loading} inverted />

              <Breadcrumb>
                  <Breadcrumb.Section><Link to="/">Home</Link></Breadcrumb.Section>
                  <Breadcrumb.Divider icon='right angle' />
                  <Breadcrumb.Section>Product</Breadcrumb.Section>
              </Breadcrumb>


          {this.state.loading?null:
            <Segment style={{ padding: '1em 0em' }} vertical>
              <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                  <Grid.Column  width={5}>
                    <Image  rounded size='large' src={this.state.product.cover}/>
                  </Grid.Column>

                  <Grid.Column floated='right' width={11}>

                    <Header as='h2' style={{ fontSize: '3em' }}>
                      {this.state.product.name}
                    </Header>

                    <Form>
                      <p>Price : {this.state.product.price} RMB, {(this.state.product.left > 0?(this.state.product.left%10+1):0 )} Left</p>
                      <p>Support: <a href="https://go.crisp.chat/chat/embed/?website_id=a8782b49-ea86-4408-8562-9535d0d3e138">Click</a></p>

                    {(this.state.product && this.state.product.id.indexOf("10") == 0 ) ?
                      <p>Only for Apple ID registered in United States.</p>
                      :
                      null
                    }

                    {(this.state.product && this.state.product.id.indexOf("20") == 0 ) ?
                      <p></p>
                      :
                      null
                    }

                      {this.state.start?
                        <Fragment>
                          <p>Please find order in your mailbox, and finish payment</p>
                        </Fragment>
                        :
                        <Fragment>
                      {this.state.product.left > 0?
                      <Fragment>
                      <Form.Group widths='equal'>
                        <Form.Input fluid
                          label={this.validateEmail(this.state.email) ? "will send order to " + this.state.email : "Please enter your email"} onChange={(e)=>{this.onChange(e)}} placeholder='Your email' />
                      </Form.Group>

                        <Button
                          type='submit'
                          color={this.validateEmail(this.state.email)?"green":"blue"}
                          size='big'
                          onClick={()=>{this.createOrder("easypay")}}
                        >Pay</Button>

                      </Fragment>
                        :
                      <Fragment>
                        <Button type='submit' disabled >Out of stock</Button>
                      </Fragment>
                      }
                        </Fragment>
                      }

                    </Form>
                    <div>
                    <br/>
                    {this.state.product.name.indexOf("GLaDOS")!=-1?<More/>:null}
                    </div>

                  </Grid.Column>

                </Grid.Row>

                {this.renderContent()}

            </Grid>

            </Segment>
          }

          <Grid>
            <Grid.Row>
                <Grid.Column width={5}>
                </Grid.Column>
                <Grid.Column width={11}>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column style={{marginTop:"35px"}}>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          </Dimmer.Dimmable>

        </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoad: () => {
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
