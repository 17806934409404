import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Menu, Container} from 'semantic-ui-react';
import AdminRouter from './Router';
import Logo from '../Logo.js';
import ResponsiveContainer from '../components/ResponsiveContainer';
import {pageTitle} from '../util';

class Admin extends Component {
    componentDidMount() {
        document.title = pageTitle('Admin');
    }
    render() {
        const {pathname} = this.props.location;
        return (
            <ResponsiveContainer
                menuColor='black'
                menuItems={[
                    <Menu.Item key='logo' header as={Link} to='/'>
                      <h2>37Apps</h2>
                    </Menu.Item>,
                    <Menu.Item key='main' active={pathname === '/admin'} as={Link} to='/admin'>数据</Menu.Item>,
                    <Menu.Item key='send' active={pathname === '/admin/send'} as={Link} to='/admin/send'>发送</Menu.Item>,
                    <Menu.Item key='send' active={pathname === '/admin/proxy'} as={Link} to='/admin/proxy'>支付测试[开发]</Menu.Item>,
                    <Menu.Item key='add' active={pathname === '/admin/add'} as={Link} to='/admin/add'>上架</Menu.Item>,
                    <Menu.Item key='assets' active={pathname === '/admin/assets'} as={Link} to='/admin/assets'>货架</Menu.Item>,
                    <Menu.Item key='orders' active={pathname === '/admin/orders'} as={Link} to='/admin/orders'>订单</Menu.Item>,
                ]}
            >
                <Container style={{marginTop: '2em', marginBottom: '3em'}}>
                    <AdminRouter />
                </Container>
            </ResponsiveContainer>
        )
    }
}

export default Admin;
