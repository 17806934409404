import {REALTIME_UPDATE} from '../actionTypes';
const reducer = (state={ realtime: {}, history: {} }, action) => {
    switch (action.type) {
        case REALTIME_UPDATE:
            var type = action.payload.type || "unknown";
            state.realtime[type] = action.payload;
            if(!state.history[type]){
              state.history[type] = [];
            }
            state.history[type].push(action.payload);
            if(!state.history[type].length > 500){
              state.history[type].shift();
            }
            return {
                ...state
            };
        default:
          return state;
    };
};

export default reducer;
