import React, {Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import ConsoleRouter from './Router';
import ResponsiveContainer from '../components/ResponsiveContainer';
import { Menu, Loader } from 'semantic-ui-react';

class Console extends Component {
    constructor() {
        super(...arguments);
    }

    componentDidMount() {
        this.props.onLoad();
    }

    onLogout(){
      console.log("hit");
    }

    render() {

        const leftMenus = [<Menu.Item key='logo' header as={Link} to='/'><h2>37 Apps</h2></Menu.Item>];
        const rightMenus = [];
        //leftMenus.push(<Menu.Item key='signout' position='right' onClick={this.onLogout}>About</Menu.Item>)

        return (
            <ResponsiveContainer
                menuItems={leftMenus}
                rightMenuItems={rightMenus}
            >
                <ConsoleRouter />
            </ResponsiveContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoad: () => {
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Console);
