import React, {Fragment,Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import axios from 'axios';
import QRCode from 'qrcode.react';
import {
  Container,
  Card,
  Breadcrumb,
  Dimmer,
  Divider,
  Header,
  Form,
  Button,
  Loader,
  Image,
  Icon,
  Segment,
  Grid
} from 'semantic-ui-react';

class Order extends Component {
    constructor({match}) {
      super()
      this.state = {
        loading:true,
        product:{
          cover:""
        }
      }
      setTimeout(()=>{
        this.fetchOrder(match.params.hash);
      },3000)
    }

    async fetchOrder (hash){
      const {data} = await axios.post("/order/" + hash);
      this.setState({
        ...data.data,
        loading:false
      });

      console.log("get Order",data)
      if(data.data.status == "created"){
        const {data} = await axios.post("/settle",{hash});
        console.log("Payment",data)
        window.location = data.redirect_url;
      }

      return;

    }

    render() {

        return (
        <Container>

          <Dimmer.Dimmable as={Segment} dimmed={this.state.loading}>
          <Dimmer active={this.state.loading} inverted />

          <Grid>
                <Grid.Row>
                  <Segment>
                    <Breadcrumb>
                      <Breadcrumb.Section><Link to="/">Home</Link></Breadcrumb.Section>
                      <Breadcrumb.Divider icon='right angle' />
                      <Breadcrumb.Section>Order</Breadcrumb.Section>
                    </Breadcrumb>
                  </Segment>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column  width={5}>
                    <Image size='large' src={this.state.product.cover}/>
                  </Grid.Column>

                  <Grid.Column floated='right' width={11}>
                    <Header as='h2'  content='Order Details' subheader={`order id ${this.state.hash}`} style={{ fontSize: '3em' }}/>
                    <p>Email to : {this.state.email}</p>
                    <p>Payment : {this.state.status}</p>

                    {this.state.status == "paid" ?
                      <Fragment>
                      <p>Your Product Keys:</p>
                      <Divider/>
                      <p>{this.state.asset.code}</p>
                      <p>{this.state.asset.password}</p>
                      <p>{this.state.asset.detail}</p>
                      <p>{this.state.asset.notes}</p>
                      <Divider/>
                      </Fragment>
                      :
                      null
                    }

                    {(this.state.product_id && this.state.product_id.indexOf("10") == 0 ) ?
                      <div><iframe width="560" height="315" src="https://www.youtube.com/embed/8BeOkirml-I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe><p>Redeem the code in your App Store(<a href="https://www.youtube.com/watch?v=8BeOkirml-I">How?</a>), if you do not have an Apple ID registered in United States, please <Link to="/shop/3001">purchase one</Link>.</p></div>
                      :
                      null
                    }

                    {(this.state.product_id && this.state.product_id.indexOf("3001") == 0 ) ?
                      <Fragment>
                        <p>怎样更改为自己的邮箱?</p>
                        <p>1. 用初始账户和密码登陆 https://appleid.apple.com </p>
                        <p>2. 点击账户右边的编辑按钮</p>
                        <p>3. 选择更改邮箱</p>
                      </Fragment>
                      :
                      null
                    }


                  </Grid.Column>

                </Grid.Row>

            <Grid.Row>
              <Grid.Column style={{marginTop:"5px"}}>
                <p>If you have any question about your order, please contact us via <a href="https://go.crisp.chat/chat/embed/?website_id=a8782b49-ea86-4408-8562-9535d0d3e138">chat</a> or hi@37apps.net</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          </Dimmer.Dimmable>

        </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoad: () => {
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
