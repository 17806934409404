import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
// import Home from './Home';
import './i18n';
import Login from './Login';
import {view as Console} from './Console';
import {view as Admin} from './Admin';
import {fetchUserInfo, realTimeUpdate} from './actions';
import Loading from './Loading';
import Loadable from 'react-loadable';
import './App.css';

axios.defaults.baseURL = '/api';

const LoadableLanding = Loadable({
    loader: () => import('./Landing'),
    loading: Loading,
});


class App extends Component {
    componentDidMount() {
        this.props.onLoad();
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/admin" component={Admin} />
                    <Route exact path="/landing" component={LoadableLanding} />
                    <Route exact path="/login" component={Login} />
                    <Route path="/" component={Console} />
                </Switch>
            </Router>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoad: () => {
        }
    };
};

export default connect(null, mapDispatchToProps)(App);
