import React from 'react';
import {Switch, Route} from 'react-router-dom';

import Main from './Pages/Main';
import Shop from './Pages/Shop';
import Order from './Pages/Order';
//import Balance from './Pages/Balance';
//import Redeem from './Pages/Redeem';
        //<Route path='/console/balance' component={Balance} />
        //<Route path='/console/redeem' component={Redeem} />
        //<Route path='/console/shop' component={Shop} />

const AllRouters = () => (
    <Switch>
        <Route exact path='/' component={Main} />
        <Route exact path='/shop/:id' component={Shop} />
        <Route exact path='/order/:hash' component={Order} />
    </Switch>
);

export default AllRouters;
