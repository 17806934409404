import React from 'react';
import {Loader, Dimmer} from 'semantic-ui-react';


export default (props) => {
    if (props.pastDelay) {
        return (
            <Dimmer active page>
                <Loader size="massive" />
            </Dimmer>
        )
    } else {
        return null;
    }
};
