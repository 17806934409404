import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import {Grid, Header, Form, Button, Segment, Input, Message} from 'semantic-ui-react';
import './styles/login.css';
import {phoneRegex} from './util';


const {Column} = Grid;
const {Field} = Form;

const options = [
    {
        "label": "中国",
        "selected":true,
        "key":"cn",
        "label_en": "China",
        "code": "+86"
    }, {
        "label": "英国",
        "key":"uk",
        "label_en": "United Kingdom",
        "code": "+44"
    }, {
        "label": "美国",
        "key":"us",
        "label_en": "USA",
        "code": "+1"
    },{
        "label": "韩国",
        "label_en": "Korea",
        "key":"kr",
        "code": "+82"
    },{
        "label": "马来西亚",
        "key":"ma",
        "label_en": "Malaysia",
        "code": "+60"
    }, {
        "label": "印度尼西亚",
        "label_en": "Indonesia",
        "key":"ia",
        "code": "+62"
    }, {
        "label": "菲律宾",
        "label_en": "Philippines",
        "key":"ph",
        "code": "+63"
    }, {
        "label": "印度",
        "label_en": "Indea",
        "key":"in",
        "code": "+91"
    }, {
        "label": "伊朗",
        "label_en": "Iran",
        "key":"ir",
        "code": "+98"
    },{
        "label": "日本",
        "label_en": "Japan",
        "key":"jp",
        "code": "+81"
    },{
        "label": "俄罗斯",
        "label_en": "Russia",
        "key":"ru",
        "code": "+7"
    }, {
        "label": "新加坡",
        "label_en": "Singapore",
        "key":"sg",
        "code": "+65"
    },{
        "label": "台湾",
        "label_en": "Taiwan",
        "key":"tw",
        "code": "+886"
    }, {
        "label": "泰国",
        "label_en": "Thailand",
        "key":"th",
        "code": "+66"
    }, {
        "label": "阿拉伯联合酋长国",
        "key":"ua",
        "label_en": "United Arab Emirates",
        "code": "+971"
}].map((i)=>{
  i.text  = i.code;//`${i.label_en}(${i.code})`;
  i.label = i.label_en;
  i.value = i.key;
  return i;
})

class Login extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            phone: '',
            code: '',
            msg: '',
            loading: false,
            userId: null,
            phoneValid: true,
            codeValid: true,
            error: false,
            success: false,
            warn: false,
            getCodeBtnContent: 'Get Code',
            status: 'init',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleGetCode = this.handleGetCode.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);


    }

    componentWillMount() {
        this.setState({
            userId: this.props.id
        });
    }

    validatePhone(phone) {
        let ret = true;
        if (!phoneRegex.test(phone)) {
            this.setState({
                phoneValid: false
            });
            ret = false;
        }
        return ret;
    }

    validateCode(code) {
        let ret = true;
        if (!code || !code.length) {
            this.setState({
                codeValid: false
            });
            ret = false
        }
        return ret;
    }

    validate(phone, code) {
        const phoneValid = this.validatePhone(phone);
        const codeValid = this.validateCode(code);
        return phoneValid && codeValid;
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit(e);
        }
        return false;
    }

    setGetCodeBtnContent(current) {
        setTimeout(() => {
            const sec = Math.round((current.getTime() + 60 * 1000 - (new Date()).getTime()) / 1000);
            if (sec <= 0) {
                this.setState({
                    getCodeBtnContent: 'Get Code',
                    status: 'done'
                });
                return;
            } else {
                this.setState({
                    getCodeBtnContent: `${sec} 秒后重试`,
                });
                this.setGetCodeBtnContent(current);
            }
        }, 1000);
    }

    handleGetCode(e) {
        e.preventDefault();
        if (this.state.status === 'waiting') {
            return;
        }
        this.setState({warn: false, success: false, error: false});
        const phone   = this.state.phone;
        const country = this.state.country;
        if (!this.validatePhone(phone)) {
            return;
        }
        this.setState({
            status: 'waiting'
        });
        const current = new Date();
        this.setGetCodeBtnContent(current);

        axios.post('/verify', {phone,country}).then((res) => {
            if (res.data.code !== 0) {
                this.setState({
                    warn: true,
                    msg: res.data.message || '',
                });
            } else {
                this.setState({
                    success: true,
                    msg: res.data.message || '验证码发送成功'
                });
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({warn: false, success: false, error: false, msg: ''});
        const {phone, code} = this.state;
        if (!this.validate(phone, code)) {
            return;
        }
        this.setState({ loading: true, msg: '', error: false, success: false, warn: false });
        axios.post('/login', {
            phone,
            code,
        }).then((res) => {
            if (res.data.code !== 0) {
                this.setState({
                    msg: res.data.message || '登录失败',
                    error: true,
                    loading: false,
                });
            } else {
                this.setState({
                    userId: res.data.data.userId,
                    loading: false,
                });
            }
        }).catch((err) => {
            console.error(err);
            this.setState({
                msg: '登录失败',
                error: true,
                loading: false,
            });
        });
    }

    handleChange(e, {name, value}) {
        this.setState({
            [name]: value,
            msg: '',
            phoneValid: true,
            codeValid: true,
            error: false,
            success: false,
            warn: false,
        });
    }

    render() {
        if (this.state.userId === 1) {
            return <Redirect from='/login' to='/admin' />;
        } else if (this.state.userId) {
            return <Redirect from='/login' to='/console' />;
        }
        return (
            <Grid textAlign='center' verticalAlign='middle' className='login-grid'>
                <Column className='login-column'>
                    <h1 style={{color:'black', fontSize:"4em", marginBottom:"0.4em"}}>GLaDOS</h1>
                    <Form onKeyPress={this.handleKeyPress} size='large' onSubmit={this.handleSubmit} error={this.state.error} success={this.state.success} warning={this.state.warn}>
                        <Segment stacked>
                            <Form.Select name="country" options={options} placeholder='Country' onChange={this.handleChange}  />
                            <Field error={!this.state.phoneValid}>
                                <Input name='phone' icon='mobile' iconPosition='left' placeholder='手机号码' onChange={this.handleChange} />
                            </Field>
                            <Field error={!this.state.codeValid}>
                                <Input name='code' type='text' icon='lock' iconPosition='left' placeholder='验证码'
                                    onChange={this.handleChange}
                                    action={{color: this.state.status === 'waiting' ? 'grey': 'blue', content: this.state.getCodeBtnContent, onClick: this.handleGetCode}}
                                />
                            </Field>
                            {this.state.msg.length ?
                            <Message error={this.state.error} success={this.state.success} warning={this.state.warn}>
                                {this.state.msg}
                            </Message> : null}
                            <Button loading={this.state.loading} size='large' fluid color='blue' type='submit'>Login</Button>
                            <p  style={{color:'grey', size:"0.7em", paddingTop:"0.7em"}} >If the phone does not exist<br/> we will create a new account for you.</p>
                        </Segment>
                    </Form>
                </Column>
            </Grid>
        )
    };
}

const mapStateToProps = (state) => {
    const user = state.user;
    return {id: (user.info && user.info.id), error: user.error};
};

export default connect(mapStateToProps)(Login);
