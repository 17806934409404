import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {reducer as Console} from './Console';
import {reducer as Admin} from './Admin';
import {reducer as User} from './reducer';

const reducer = combineReducers({
    console: Console,
    user: User,
    admin: Admin,
});

const middlewares = [thunkMiddleware];

const storeEnhancers = compose(
    applyMiddleware(...middlewares),
    (f) => f,
);

export default createStore(reducer, {}, storeEnhancers);
